import React from 'react';
import { Input, Button, Select, Viewer, Modal, Pagination, Tooltip } from 'components';
import { RootState } from 'reducers';
import { RouteComponentProps } from 'react-router-dom';
import { Globals, Constants, Colors } from 'utils';
import { connect } from 'react-redux';
import { 
	CheckWhiteIcon, 
	AddIcon, 
	VerificationManualIcon, 
	FaceIcon, 
	CheckUserIcon, 
	IdCardIcon, 
	CloseIcon,
	SalarymanIcon,
	FaceIdentityIcon,
	AlertIcon,
	BlackListIcon,
	BlackListRedIcon
} from 'assets/icons';
import moment from 'moment';
import { ReportService } from 'services';

interface Props extends RootState, RouteComponentProps {
	dispatch: any
}

class ViewVerification extends React.Component<Props> {

	state = {
		verification: null,
		file: null,
		visible: false,
		visible_modal: false,
		type: null,
		identity_token: null,
		page: 1,
		last_page: 1,
		black_list: []
	}

	componentDidMount() {
		this.props.dispatch({
			type: 'SET_HEADER',
			payload: {
				title: 'Ver verificación',
				onBack: () => {
					this.props.history.replace((this.props.user?.level_id == Constants.LEVELS.CLIENT ? '/client' : '/admin') + '/reports/verifications');
				}
			}
		});

		this.props.dispatch({
			type: 'SET_SUBHEADER',
			payload: {
				title: ''
			}
		});

		this.load();
	}

	load = async (withoutLoading = false) => {
		// @ts-ignore
		const token_id: number = this.props.match?.params?.id;

		if (!token_id) {
			this.props.history.replace((this.props.user?.level_id == Constants.LEVELS.CLIENT ? '/client' : '/admin') + '/reports/verifications');
		}

		const res: any = await ReportService.admin.viewVerification({
			token_id,
			page: this.state.page,
			withoutLoading
		});
		this.setState({
			verification: res.verifications.rows.length > 0 ? res.verifications.rows[0] : null,
			identity_token: res.identity_token,
			last_page: res.verifications.count,
			black_list: res.black_list || []
		});
	}

	setVisible = (value: boolean) => {
		this.setState({
			visible: value
		});
	}

	view = (url: string) => {
		this.setState({
			visible: true,
			file: url
		});
	}

	changeStatus = (type: number) => {
		this.setState({
			visible_modal: true,
			type
		});
	}

	changeVerification = () => {
		this.setState({
			visible_modal: true,
			type: Constants.CHANGE_VERIFICATION_TYPES.RESULT
		});
	}
	
	onClose = () => {
		this.setState({
			visible_modal: false,
			type: null
		},() => this.load());
	}

	setBlackList = async (e: any, value: string, is_mac = false) => {
		e.stopPropagation();
		await ReportService.admin.addBlackList({
			[is_mac ? 'mac' : 'image']: value,
			// @ts-ignore
			identity_verification_id: this.state.verification?.id
		});
		this.load(true);
	}

	saveStatus = (result: boolean, text: string) => {
		Globals.confirm(`¿Desea cambiar el estatus a ${ text }?`,async () => {
			await ReportService.admin.saveStatus({
				type: this.state.type,
				// @ts-ignore
				verification_id: this.state.verification?.id,
				result
			});
			Globals.showSuccess("Se ha cambiado el estatus correctamente");
			this.onClose();
		});
	}

	getColor = () => {
		let color = '';

		// @ts-ignore
		switch(this.state.verification?.result) {
			case Constants.IDENTITY_VERIFICATION_RESULT.YES:
				color = Colors.green;
			break;

			case Constants.IDENTITY_VERIFICATION_RESULT.NO:
				color = Colors.danger;
			break;
		}

		return color;
	}

	render() {
		const verification: any = this.state.verification;
		const identity_token: any = this.state.identity_token;
		const { visible, visible_modal } = this.state;
		const document_photo_in_black_list = this.state.black_list.map((i: any) => i.image).indexOf(verification?.document_photo) != -1;
		const selfie_in_black_list = this.state.black_list.map((i: any) => i.image).indexOf(verification?.selfie) != -1;
		const mac_in_black_list = this.state.black_list.map((i: any) => i.mac).indexOf(verification?.mac) != -1;

		return (
			<div id="view-verification">
				<Viewer
			      visible={ visible }
			      onClose={ () => this.setVisible(false) }
			      images={ [{ src: this.state.file }] }
			    />

			    <Modal
		          className="modal-change-status-verification"
		          visible={ visible_modal }
		          title="Cambiar estado"
		          onClose={ () => this.onClose() }
		        >
			        <div id="modal-change-status-verification">
			          <div className="row row-status">
			          	<div className="col-md-6 text-center">
			          		<button className="btn btn-danger" onClick={ () => this.saveStatus(false,'Rechazado') }>
			          			Rechazado
			          		</button>
			          	</div>
			          	<div className="col-md-6 text-center">
			          		<button className="btn btn-success" onClick={ () => this.saveStatus(true,'Aprobado') }>
			          			Aprobado
			          		</button>
			          	</div>
			          </div>
			        </div>
		      	</Modal>

		      	{
		      		verification != null && (
						<div className="row">
				      		<div className="col-md-3">
				      			<button className="btn btn-verify" onClick={ () => this.changeVerification() }>
									<img src={ VerificationManualIcon } />
									Cambiar estado
								</button>	
				      		</div>
				      		<div className="col-md-3">
				      			<h3 className="status" style={{ color: this.getColor() }}><strong>Estado:</strong> { verification?.result_text }</h3>
				      		</div>
				      	</div>
		      		)
		      	}
		      	<div className="row" style={{ marginBottom: '8px' }}>
		      		<div className="col-md-6">
		      			<p className="id-usuario">idUsuario: { identity_token?.value }</p>
		      		</div>
		      		{
		      			this.state.last_page > 1 && (
		      				<div className="col-md-6 row">
				      			<div className="col-md-6">
				      				<h3 className="attempts">Intentos</h3>
				      			</div>
				      			<div className="col-md-6">
					      			<Pagination 
										pages={ this.state.last_page } 
										active={ this.state.page }
										onChange={ async (page: number) => {
											await this.setState({
												page: page
											});
											this.load();
										} } />
								</div>
				      		</div>
		      			)
		      		}
		      	</div>
				<div className="row">
					<div className="col-md-6">
						<div className="container-blue">
							<div className="container-title">
								<h3>Información Personal</h3>
								{/*<button className="btn">
									Editar
								</button>*/}
							</div>
							<hr />
							<div className="row">
								<div className="row col-md-12" style={{ margin: '0px', padding: '0px' }}>
									<div className="col-md-6">
										<p className="name-title">Datos suministrados por el usuario:</p>
										<p className="value"><strong>Nombres:</strong> { identity_token?.request_name }</p>
										<p className="value"><strong>Apellidos:</strong> { identity_token?.request_lastname }</p>
									</div>
									{
										verification != null && (
											<div className="col-md-6">
												<p className="name-title">Datos Verificados:</p>
												<p className="value"><strong>Nombres:</strong> { verification?.name }</p>
												<p className="value"><strong>Apellidos:</strong> { verification?.lastname }</p>
											</div>
										)
									}
								</div>
								{
									verification != null && (
										<>
											{
												(!verification?.name_verification) && (
													<div className="col-md-12">
														<div className="alert-container">
															<img src={ AlertIcon } />
															<p>Datos del documento no coincide</p>
														</div>
													</div>
												)
											}
											{
												!verification.only_faces && (
													<>
														<div className="col-sm-6">
															<p className="bold">Fecha de Nacimiento</p>
															<p className="value">{ moment(verification?.birthdate).isValid() ? moment(verification?.birthdate).format('DD/MM/YYYY') : '-' }</p>
														</div>
														<div className="col-sm-6">
															<p className="bold">Edad</p>
															{
																verification != null && (
																	<p className="value">{ moment(verification?.birthdate).isValid() ? Globals.calculateAge(verification?.birthdate) : '-' }</p>
																)
															}
														</div>
													</>
												)
											}
											{
												(!verification?.birthdate_verification) && (
													<div className="col-md-12">
														<div className="alert-container">
															<img src={ AlertIcon } />
															<p>Fecha de nacimiento no coincide</p>
														</div>
													</div>
												)
											}
											{
												verification?.ip != null && (
													<div className="col-md-6">
														<p className="name-title">Dirección IP:</p>
														<p className="value">{ verification?.ip }</p>
													</div>
												)
											}
											{
												verification?.mac != null && (
													<div className="col-md-6">
														<p className="name-title">ID Dispositivo:</p>
														<p className="value">
															{ verification?.mac }
															<Tooltip title={ mac_in_black_list ? 'Eliminar de lista negra' : 'Agregar a lista negra' }>
																<img src={ mac_in_black_list ? BlackListRedIcon : BlackListIcon } className="black-list-mac" onClick={ (e: any) => this.setBlackList(e,verification?.mac,true) } />
															</Tooltip>
														</p>
													</div>
												)
											}
										</>
									)
								}
							</div>
						</div>
					</div>
					<div className="col-md-6">
						{
							verification != null && (
								<div className="container-blue white">
									<div className="container-title">
										<h3>Estado del candidato</h3>
									</div>
									<hr />
									{
										!verification.only_faces && (
											<>
												<div className="title-check">
													<div>
														<div className={ `container-check ${ (!verification?.name_verification || !verification?.birthdate_verification) ? 'red' : '' }` }>
															<img src={ verification?.name_verification && verification?.birthdate_verification ? CheckWhiteIcon : CloseIcon } />
														</div>
													</div>
													<h4>Datos suministrados</h4>
												</div>
												<div className="title-check">
													<div>
														<div className={ `container-check ${ (!moment(verification?.birthdate).isValid() || Globals.calculateAge(verification?.birthdate) < 18) ? 'red' : '' }` }>
															<img src={ (moment(verification?.birthdate).isValid() && Globals.calculateAge(verification?.birthdate) >= 18) ? CheckWhiteIcon : CloseIcon } />
														</div>
													</div>
													<h4>Edad Mínima</h4>
												</div>
											</>
										)
									}
									<div className="title-check">
										<div>
											<div className={ `container-check ${ !verification?.face_verification ? 'red' : '' }` }>
												<img src={ verification?.face_verification ? CheckWhiteIcon : CloseIcon } />
											</div>
										</div>
										<h4>Reconocimiento facial</h4>
									</div>
									<div className="title-check">
										<div>
											<div className={ `container-check ${ (!verification?.liveness1 || !verification?.liveness2) ? 'red' : '' }` }>
												<img src={ verification?.liveness1 && verification?.liveness2 ? CheckWhiteIcon : CloseIcon } />
											</div>
										</div>
										<h4>Detección de persona real</h4>
									</div>
									{
										!verification.only_faces && (
											<div className="title-check">
												<div>
													<div className={ `container-check ${ (!verification.document_object_verification) ? 'red' : '' }` }>
														<img src={ verification.document_object_verification ? CheckWhiteIcon : CloseIcon } />
													</div>
												</div>
												<h4>Documento de identidad no corresponde</h4>
											</div>
										)
									}
								</div>
							)
						}
					</div>
					{
						verification != null && (
							<>
								<div className="col-md-6">
									<div className="title-check">
										<div className="container-title">
											<div className="container-icon">
												<img src={ IdCardIcon } />
											</div>
											<h4>Documentos</h4>
										</div>
										{/*<button className="btn">
											<img src={ AddIcon } />
											Añadir nuevo documento
										</button>*/}
									</div>
									<div className="container-gray">
										{
											verification?.document_photo != null && (
												<div className="container-image" onClick={ () => this.view(Globals.generateS3(verification?.document_photo,verification.s3)) }>
													<img src={ Globals.generateS3(verification?.document_photo,verification.s3) } />
													<div className="container-black-list" onClick={ (e: any) => this.setBlackList(e,verification?.document_photo) }>
														<Tooltip title={ document_photo_in_black_list ? 'Eliminar de lista negra' : 'Agregar a lista negra' }>
															<img src={ document_photo_in_black_list ? BlackListRedIcon : BlackListIcon } className="black-list" />
														</Tooltip>
													</div>
												</div>
											)
										}
										<div style={{ padding: '10px' }}>
											<div className="container-title">
												<h3>Documento de Identidad</h3>
												{/*<button className="btn">
													Editar
												</button>*/}
											</div>
											<p className={ `result ${ (!verification?.document_verification || !verification.document_object_verification || (!verification.document_expiration_date && !verification.only_faces)) ? 'red' : '' }` }>Resultado de la consulta: 
												<span>
													<div className={ `container-check ${ (!verification?.document_verification || !verification.document_object_verification || (!verification.document_expiration_date && !verification.only_faces)) ? 'red' : '' }` }>
														<img src={ (verification?.document_verification && verification.document_object_verification && (verification.document_expiration_date || verification.only_faces)) ? CheckWhiteIcon : CloseIcon } />
													</div>
													{ (verification?.document_verification && verification.document_object_verification && (verification.document_expiration_date || verification.only_faces)) ? 'Aprobado' : 'Rechazado' }
												</span>
											</p>
											<div className="row">
												<div className="col-sm-6">
													<p className="bold">Número</p>
													<p className="value">{ verification?.document || '-' }</p>
												</div>
												{
													!verification.only_faces && (
														<div className="col-sm-6">
															<p className="bold">Válido Hasta</p>
															<p className="value">{ moment(verification?.document_expiration_date).isValid() ? moment(verification?.document_expiration_date).format('DD/MM/YYYY') : '-' }</p>
														</div>
													)
												}
											</div>
												
											{
												!verification?.document_object_verification && (
													<div className="alert-container">
														<img src={ AlertIcon } />
														<p>Documento de identidad no corresponde</p>
													</div>
												)
											}
											<button className="btn btn-change" onClick={ () => {
												this.changeStatus(Constants.CHANGE_VERIFICATION_TYPES.DOCUMENT);
											}}>
												Cambiar estado
											</button>
										</div>
									</div>
								</div>
								<div className="col-md-6">
									<div className="title-check">
										<div className="container-title">
											<div className="container-icon">
												<img src={ FaceIcon } />
											</div>
											<h4>Selfie</h4>
										</div>
										{/*<button className="btn">
											<img src={ AddIcon } />
											Cargar nuevo selfie
										</button>*/}
									</div>
									<div className="container-gray">
										{
											verification?.selfie != null && (
												<div className="container-image" onClick={ () => this.view(Globals.generateS3(verification?.selfie,verification?.s3)) }>
													<img src={ Globals.generateS3(verification?.selfie,verification?.s3) } />
													<div className="container-black-list" onClick={ (e: any) => this.setBlackList(e,verification?.selfie) }>
														<Tooltip title={ selfie_in_black_list ? 'Eliminar de lista negra' : 'Agregar a lista negra' }>
															<img src={ selfie_in_black_list ? BlackListRedIcon : BlackListIcon } className="black-list" />
														</Tooltip>
													</div>
												</div>
											)
										}
										<div style={{ padding: '10px' }}>
											<p className="facial-title">Probabilidad de coincidencia con documento: <span>{ verification?.face_verification_percentage.toFixed(0) }%</span></p>
											<div className="row">
												<div className="col-md-7">
													<p className={ `result ${ !verification?.face_verification ? 'red' : '' }` }>Resultado de la consulta: 
														<span>
															<div className={ `container-check ${ !verification?.face_verification ? 'red' : '' }` }>
																<img src={ verification?.face_verification ? CheckWhiteIcon : CloseIcon } />
															</div>
															{ verification?.face_verification ? 'Aprobado' : 'Rechazado' }
														</span>
													</p>
												</div>
												<div className="col-md-5 text-right">
													<button className="btn btn-change" onClick={ () => {
														this.changeStatus(Constants.CHANGE_VERIFICATION_TYPES.FACE);
													}}>
														Cambiar estado
													</button>
												</div>
											</div>
										</div>
									</div>
								</div>
							</>
						)
					}
				</div>
				{
					verification != null && (
						<>
							<hr />
							<div className="title-check">
								<div className="container-title">
									<div className="container-icon">
										<img src={ SalarymanIcon } />
									</div>
									<h4>Detección de persona real</h4>
								</div>
							</div>
							<p className="date">Fecha de consulta: { moment(verification?.created_at).format('DD MMM YYYY') }</p>
							<div className="row row-facial-change">
								<div className="col-md-6">
									<p style={{ marginTop: '20px' }} className={ `result ${ (!verification?.liveness1 || !verification?.liveness2) ? 'red' : '' }` }>Resultado de la consulta: 
										<span>
											<div className={ `container-check ${ (!verification?.liveness1 || !verification?.liveness2) ? 'red' : '' }` }>
												<img src={ (verification?.liveness1 && verification?.liveness2) ? CheckWhiteIcon : CloseIcon } />
											</div>
											{ (verification?.liveness1 && verification?.liveness2) ? 'Aprobado' : 'Rechazado' }
										</span>
									</p>
								</div>
								<div className="col-md-6">
									<button className="btn btn-change" onClick={ () => {
										this.changeStatus(Constants.CHANGE_VERIFICATION_TYPES.LIVENESS);
									}}>
										Cambiar estado
									</button>
								</div>
							</div>
							<div className="row">
								<div className="col-md-4">
									{
										verification?.face1_file && (
											<div className="container-gray">
												<div className="container-image" onClick={ () => this.view(Globals.generateS3(verification?.face1_file,verification.s3)) }>
													<img src={ Globals.generateS3(verification?.face1_file,verification.s3) } />
												</div>
											</div>
										)
									}
								</div>
								<div className="col-md-4">
									{
										verification?.face2_file && (
											<div className="container-gray">
												<div className="container-image" onClick={ () => this.view(Globals.generateS3(verification?.face2_file,verification.s3)) }>
													<img src={ Globals.generateS3(verification?.face2_file,verification.s3) } />
												</div>
											</div>
										)
									}
								</div>
							</div>
						</>
					)
				}
			</div>
		)
	}
}

export default connect((state: RootState) => {
	return {
		user: state.user
	}
})(ViewVerification);